import * as React from "react"
import { Link } from "gatsby"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"

const ServicosPage = () => (
  <>
    <h1>Hi from Serviços</h1>
    <Link to="/">Go back to the homepage</Link>
  </>
)

export const Head = () => <Seo title="Serviços" />

export default ServicosPage
